import { Injectable } from '@angular/core';
import { Shift4 } from '@aaa/emember/store-services';
import { Flex } from '../../join-renew/hoosier/services/cybersource';
import { WindowMetaData } from '../../../interfaces/window';

// This interface is optional, showing how you can add strong typings for custom globals.
// Just use "Window" as the type if you don't have custom global stuff
export interface ICustomWindow extends Window {
  __custom_global_stuff: string;
  Flex: Flex;
  Shift4: Shift4;
  metaData: WindowMetaData;
  google_trackConversion: any;
  ga: any;
  dataLayer: any[];
  avaDataLayer: Partial<{
    [key: string]: string | number;
    event: string;
    category: string;
    action: string;
    label: string;
  }>[];
}

function getWindow(): ICustomWindow {
  // @ts-ignore
  return window;
}

@Injectable({ providedIn: 'root' })
export class WindowRefService {
  get nativeWindow(): ICustomWindow {
    return getWindow() as ICustomWindow;
  }
}
