import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import SHA256 from 'crypto-js/sha256';

@Injectable({
  providedIn: 'root',
})
export class ScriptsService {
  renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  addJsToHead(src: string): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    const head = this.document.querySelector('head');
    this.renderer.appendChild(head, script);
    return script;
  }

  addJs(src: string) {
    return new Promise((resolve, reject) => {
      const id = 'script' + SHA256(src);
      const existing = document.querySelector('#' + id);

      try {
        if (!existing) {
          const script = this.renderer.createElement('script');
          script.id = id;
          script.type = 'text/javascript';
          script.src = src;
          const head = this.document.querySelector('head');
          this.renderer.appendChild(head, script);

          script.onload = () => {
            resolve(script);
          };
        } else {
          resolve(existing);
        }
      } catch (e) {
        reject(e);
      }
    });
  }
}
